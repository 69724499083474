import React, { useContext, useEffect } from "react";
import styles from "./Onboarding.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Table } from "react-bootstrap";
import noLoan from "../../assets/no-loan.png";
import welcomeToAcc from "../../assets/welcomeAcc.png";
import somoLogo from "../../assets/logo12.png";
import somoLogo2 from "../../assets/logo.png";
import { Row, Col, Modal } from "react-bootstrap";
import { Fa500Px, FaAccusoft, FaAmilia, FaArrowRight, FaBatteryEmpty, FaBomb, FaCarBattery, FaCheckCircle, FaClock, FaRocket, FaStopwatch, FaWalking } from "react-icons/fa";
import { TiCancelOutline } from "react-icons/ti";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
// import Loader from "react-spinners/BounceLoader";
// import Loader1 from "react-spinners/ScaleLoader";
// import Loader2 from "react-spinners/ClimbingBoxLoader";
// import Loader3 from "react-spinners/ClipLoader";
import LoaderPre from "react-spinners/ClockLoader";
// import Loader5 from "react-spinners/RingLoader";
// import Loader6 from "react-spinners/PuffLoader";
// import Loader7 from "react-spinners/RotateLoader";
import LoaderIntroWait from "react-spinners/HashLoader";
import LoaderIntro from "react-spinners/SyncLoader";
// import Loader10 from "react-spinners/PacmanLoader";
// import Loader11 from "react-spinners/PropagateLoader";
// import Loader12 from "react-spinners/PuffLoader";
// import Loader13 from "react-spinners/PulseLoader";
import LoanCalculatorForm from "../../components/LoanCalculatorForm/LoanCalculatorForm";
import LoanContactForm from "../../components/LoanContactForm/LoanContactForm";
import EmployerInfoForm from "../../components/EmployerInfoForm/EmployerInfoForm";
import BankInfoForm from "../../components/BankInfoForm/BankInfoForm";
import LoanFundForm from "../../components/LoanFundForm/LoanFundForm";
import LoanProposalForm from "../../components/LoanProposalForm/LoanProposalForm";
import LoanAmountForm from "../../components/LoanAmountForm/LoanAmountForm";
import OnboardPassportForm from "../../components/OnboardPassportForm/OnboardPassportForm"; 
import OnboardIdFrontForm from "../../components/OnboardIdFrontForm/OnboardIdFrontForm"; 
import OnboardIdBackForm from "../../components/OnboardIdBackForm/OnboardIdBackForm"; 
import OnboardKRApinForm from "../../components/OnboardKRApinForm/OnboardKRApinForm";
import OnboardBNameForm from "../../components/OnboardBNameForm/OnboardBNameForm"; 
import OnboardBBankForm from "../../components/OnboardBBankForm/OnboardBBankForm"; 
import OnboardInvestOverviewForm from "../../components/OnboardInvestOverviewForm/OnboardInvestOverviewForm"; 
import OnboardInvestVideoForm from "../../components/OnboardInvestVideoForm/OnboardInvestVideoForm"; 
import OnboardInvestImageForm from "../../components/OnboardInvestImageForm/OnboardInvestImageForm"; 
import OnboardInvestLogoForm from "../../components/OnboardInvestLogoForm/OnboardInvestLogoForm"; 
import OnboardEmapForm from "../../components/OnboardEmapForm/OnboardEmapForm"; 
import OnboardConductForm from "../../components/OnboardConductForm/OnboardConductForm"; 
import OnboardPhotographyForm from "../../components/OnboardPhotographyForm/OnboardPhotographyForm"; 
import OnboardAgreementForm from "../../components/OnboardAgreementForm/OnboardAgreementForm"; 

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { onboardRoutes } from "../../routes/sidebarRoutes";
import _ from "lodash";

const Onboarding = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const applyStageArray = {
    "/dashboard/onboarding/apply/activate-done-at-login":0,
    "/dashboard/onboarding/apply/start":1,
    "/dashboard/onboarding/apply/id_front":2,
    "/dashboard/onboarding/apply/id_back":3,
    "/dashboard/onboarding/apply/kra_pin":4,
    "/dashboard/onboarding/apply/b_name":5,
    "/dashboard/onboarding/apply/b_bank":6,
    "/dashboard/onboarding/apply/invest_overview":7,
    "/dashboard/onboarding/apply/invest_video":8,
    "/dashboard/onboarding/apply/invest_image":9,
    "/dashboard/onboarding/apply/invest_logo":10,
    "/dashboard/onboarding/apply/e_map":11,
    "/dashboard/onboarding/apply/conduct":12,
    "/dashboard/onboarding/apply/photography":13,
    "/dashboard/onboarding/apply/final":14,
    // "/dashboard/consumer-credit/apply/calculator": 0,
    // "/dashboard/consumer-credit/apply/fund": 1,
    // "/dashboard/consumer-credit/apply/amount": 2,
    // "/dashboard/consumer-credit/apply/bank-info": 3,
  };

  const {
    state: { loans, error, currentLoanId, incomplete, loanApplicationStage },
    retrieveClientLoans,
    clearError,
    loanApply,
    addAddressForLoan,
    addWorkInfoForLoan,
    addBankInfoForLoan,
    clearCompleteState,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(()=>{
    // console.log('psr>>', user);
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetOnboardingState();      
    // });
    getCurrentOnboardingState(user.user_id);  
    function getCurrentOnboardingState(usrId){
      var y = new XMLHttpRequest();
      y.onload = function(){
        try{
          var a = JSON.parse(y.responseText);
          if(a.error){
            console.log('load Error > :3', y.responseText);
            toast.error("Error Occured" + a.error);
            if(a.data.path){
              if(window.location.pathname != a.data.path && a.data.path == '/dashboard/onboarding/too_late'){
                history.push(a.data.path);
                // window.location.href = a.data.path;
                try{document.getElementById('onboarding_box_holder').style.display = 'none';}catch(e){}
              } else if(window.location.pathname == '/dashboard/onboarding/too_late'){
                try{document.getElementById('onboarding_box_holder').style.display = 'none';}catch(e){}
              }
            }
          }
          // console.log('usr', a);
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Helllllllllllllo');
          if(a.data.firstAttempt){
            if(window.location.pathname != '/dashboard/onboarding/welcome'){
              history.push('/dashboard/onboarding/welcome');
              // window.location.href = '/dashboard/onboarding/welcome';
              return 0;
            }
            document.getElementById('preSetsLoaderA').style.display = 'none';
            document.getElementById('preSetsForm').style.display = 'block';
          } else {
            if(window.location.pathname == '/dashboard/onboarding/welcome'){
              try{ document.getElementById('preSetsParent').style.display = 'none'; } catch(e){}
              if(user.onboardingComplete == true && a.data.path=='/dashboard/onboarding/success'){
                window.location.href = '/dashboard/onboarding/success';
              }
            }
            // console.log('>1>>', a.data);
          }
          // console.log('>>checking>>1', a.data.timeLeft);
          function incrementAnimate(options){
            try{
              var elm = options.elm;
              var max = parseInt(options.max);
              var min = options.min ? parseInt(options.min) : (max-7);
              var sec = options.sec ? options.sec : 90;
              var interval = setInterval(function() {
                elm.innerText = min + '%';
                if (min >= max) clearInterval(interval);
                min++;
              }, sec);
            } catch(er){

            }
          }
          document.getElementById('onboarding_progress').scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
          document.getElementById('onboarding_progress').innerText = a.data.progress;
          document.getElementById('onboarding_progress_b').innerText = a.data.stepsDone + '/' + a.data.totalSteps;
          document.getElementById('onboarding_steps_p').innerText = a.data.pendingApproval;
          document.getElementById('onboarding_days_l').innerText = a.data.daysLeft + ' days, ' + a.data.timeLeft.hour + ' hrs, ' + a.data.timeLeft.mins + ' mins ' /*+ a.data.timeLeft.secs + ' sec'*/;
          localStorage.setItem('AccSelfOnboard', JSON.stringify(a.data.onboarding));
          incrementAnimate({
            elm: document.getElementById('onboarding_progress'), 
            max: a.data.progress.replace('%', ''), 
            min: 0, 
            sec: 120
          });
          if(document.getElementById('stepsAwaitingApproval')){
            var tmp = '';
            var pt = {};
            for(pt of a.data.toApproveSteps){
              tmp += '<li>' + pt.name + '</li>';
            }
            document.getElementById('stepsAwaitingApproval').innerHTML = tmp;
            console.log('>>>>>>>>>>>>>>>>>>>>>>yes');
          }
          // console.log('>>checking>>2', a.data.timeLeft);
          if(a.data.rejectedSteps.length > 0){
            toast.error(a.msg);
          }
          if(a.data.path){
            if(a.data.path == '/dashboard/onboarding/success'){
              var info = JSON.parse(localStorage.gypsy);
              info.user.onboardingComplete = true;
              localStorage.setItem('gypsy', JSON.stringify(info));
            }
            if(!user.useNewOnboarding){
              toast.info('Onboarding was done with the old manual system');
            } //else toast.info('Onboarding was done with the new automatic system');
            if(window.location.pathname != a.data.path && ((window.location.pathname == '/dashboard/onboarding/welcome' && a.data.stepsDone>1 && a.data.path != '/dashboard/onboarding/apply/start') || window.location.pathname != '/dashboard/onboarding/welcome')){
              toast.info("Continue with your onboarding application");
              history.push(a.data.path);
              if([
                '/dashboard/onboarding/approvals',
                '/dashboard/onboarding/success',
                '/dashboard/onboarding/too_late'
              ].includes(a.data.path)) window.location.href = a.data.path;
            }
          } else {
          }
        } catch(er){
          console.log('load Error > :2', y.responseText, er);
        }
      };
      y.onerror = function(){
        console.log('load Error > :1', y.responseText);
      };
      y.open('GET', globalVars.baseUrl + globalVars.getOnboardingState.replace(':user_id', usrId) + '?start=1');
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.send();
    }
  });

  // useEffect(() => {
  //   retrieveClientLoans();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      clearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const placeholderFun = (data) => {
    // loanApply(data, user.user_id);
  };

  const calculateLoan = (data) => {
    loanApply(data, user.user_id);
  };

  const updateAddress = (data) => {
    addAddressForLoan(data, currentLoanId);
  };

  const updateEmployerInfo = (data) => {
    addWorkInfoForLoan(data, currentLoanId);
  };

  const updateBankInfo = (data) => {
    addBankInfoForLoan(data, currentLoanId);
  };

  const resumeApplication = () => {
    clearCompleteState();
    if (loanApplicationStage === "calculated") {
      history.push("/dashboard/consumer-credit/apply/contact-info");
    }
    if (loanApplicationStage === "address_added") {
      history.push("/dashboard/consumer-credit/apply/employer-info");
    }
    if (loanApplicationStage === "employer_added") {
      history.push("/dashboard/consumer-credit/apply/bank-info");
    }
  };

  function handlePreSetsForm(){
    var usrLoc = document.getElementById('locationValue').value;
    if(!usrLoc){
      document.getElementById('preSetsInfo').innerText = 'Select the location of the hub closest to you';
      toast.error('Select the location of the hub closest to you');
      return 0;
    }
    document.getElementById('preSetsLoader').style.display = 'inline';
    document.getElementById('preSetsLoaderWait').style.display = 'flex';
    document.getElementById('preSetsInfo').innerText = '';
    var tmt = setTimeout(function(){window.location.reload();}, 1000*60*3);
    var x = new XMLHttpRequest();
    x.onload = function(){
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
      try{ clearTimeout(tmt); } catch(er){ console.log(er); }
      try{
        var a = JSON.parse(x.responseText);
        if(!a.status){
          console.log('load Error > :3', x.responseText);
          toast.error("Error!! " + a.message);
          document.getElementById('preSetsInfo').innerText = (a.error ? a.error : "Error Occured");
        } else {
          document.getElementById('preSetsParent').style.display = 'none';
        }
        console.log('res', a);
      } catch(er){
        console.log('load Error > :2', x.responseText, er);
        toast.error("Error!! " + er);
        document.getElementById('preSetsInfo').innerText = ("Error Occured" + er);
      }
    };
    x.onerror = function(){
      try{ clearTimeout(tmt); } catch(er){ console.log(er); }
      console.log('load Error > :1', x.responseText);
      toast.error('Error! Try again');
      document.getElementById('preSetsInfo').innerText = 'Error! Try again';
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
    };
    x.open('GET', globalVars.baseUrl + globalVars.getOnboardingState.replace(':user_id', user.user_id) + '?location=' + usrLoc);
    x.setRequestHeader("Authorization", `Bearer ` + resolveToken());
    x.send();
  }

  // console.log(error);

  return (
    <>
      <Dashboard sidebarRoutes={onboardRoutes} location={location}>
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <div id="onboarding_box_holder">
            <h2>Onboarding <span id="onboarding_progress">7%</span></h2>
            <p style={{marginBottom:'0.1rem'}}>Your have completed  <b><span id="onboarding_progress_b">1/15</span></b> onboarding steps<br/>
            <b><span id="onboarding_steps_p">0</span></b> steps pending approval</p>
            <div style={{display:'flex', alignItems:'center', position:'relative', paddingLeft:'28px'}}>
              <span className={styles.timer}><LoaderPre/></span>
              <span id="onboarding_days_l" style={{display:'inline-flex', padding: '0 3px 0 9px'}}>90 days </span> to deadline
            </div>
          </div>
          {location.pathname === "/dashboard/onboarding/welcome" && (
            <Button
              bgColor="#cc6328"
              size="sm"
              color="#fff"
              className="mt-4"
              clicked={() =>
                history.push("/dashboard/onboarding/apply/start")
              }
            >
              Start Now <FaRocket />
            </Button>
          )}
        </div>
        <Switch>
          <Route path={`${path}/welcome`}>
            <div className={styles.preSets} id="preSetsParent">
              <div>
                <span id="preSetsLoaderA" style={{display:'flex', alignItems:'center', justifyContent:'center', height:'28px'}}>Loading...&nbsp;<LoaderPre/></span>
                <form id="preSetsForm" style={{display:'none'}} onSubmit={(e)=>{e.preventDefault(); handlePreSetsForm();}}>
                  <img src={somoLogo} alt="Somo" />
                  <span id="preSetsInfo"></span>
                  <p>Select your Hub location</p>
                  <label for="proposalUpload">
                    <select id="locationValue" name="location" onChange={(e)=>{e.preventDefault();}}>
                      <option value="" selected={true} disabled={true}>Select Location</option>
                      <option value="Nairobi">Nairobi</option>
                      <option value="Kisumu">Kisumu</option>
                      <option value="Mombasa">Mombasa</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Nakuru">Nakuru</option>
                    </select>
                  </label>
                  <button type="submit" onClick={(e)=>{e.preventDefault(); handlePreSetsForm();}}>
                    Save & Continue
                    <span id="preSetsLoader" style={{display:'none'}}><LoaderIntro/></span>
                  </button>
                  <div id="preSetsLoaderWait" className={styles.preSetsWait} style={{display:'nones'}}>
                    <LoaderIntroWait/>
                    <p>
                      <b>Setting Up Your Somo Space...</b><br/>
                      This may take a few minutes (2 to 5 mins)<br/>
                      Be patient and do not refresh the page.<br/>
                      Leave everything unto us.
                    </p>
                    <img src={somoLogo2} alt="Somo" />
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.creditTable}>
              <div className={styles.welcomeAcc}>
                <h2>Welcome to Acceleration</h2>
                <img src={welcomeToAcc} alt="Welcome to Acceleration" style={{maxHeight:"250px"}} />
                <hr/><p>Congratulations for making it this far. It's time to build your business and accelerate its growth.<br/> 
                  But first, let's get you onboarded into Acceleration.<br/> 
                  Here's what you need to submit to complete your onboarding process.
                </p>
                <ol className={styles.steps}>
                  <li>Passport photo</li>
                  <li>Copy of your ID</li>
                  <li>Copy of KRA Pin</li>
                  <li>Registered business name certificate</li>
                  <li>Opened bank account for your business</li>
                  <li>
                    Minimum <a href="https://invest.somoafrica.org" target="_">Somo Invest</a> details
                    <ul>
                      <li>Business Overview</li>
                      <li>Business Video</li>
                      <li>Business Logo</li>
                      <li>Business Image</li>
                    </ul>
                  </li>
                  <li>Filled Entrepreneur Map</li>
                  <li>Signed Code of conduct</li>
                  <li>Signed Photography Consent</li>
                  <li>Signed Acceleration Agreement letter</li>
                </ol>
                <i>
                  *You will only go to the next step after completing the previous step.<br/>
                  You only have 90 days to complete all the onboarding steps
                </i>
                <p>In addition, you need to be on DigiKua for your record keeping and Somo Invest.<br/> 
                  You'll also need to create your milestone budget before receiving your grant.<br/> 
                  And congratulations! By logging into this entrepreneur portal and getting to this page, you've completed 7% of the onboarding steps.<br/>
                  Remember to check Somo Invest(<a href="https://invest.somoafrica.org" target="_">somoafrica.org/invest</a>), to get insights in creating your own profile.<br/> 
                  You can download the various documents below for review and start your onboarding.
                </p>
                <ul className={styles.dlinks}>
                  <li><a target="_" download="" href="/files/Somo_Acceleration_Program.pdf">Somo Acceleration Program</a></li>
                  <li><a target="_" download="" href="/files/SOMO_STEPS_TO_ELIGIBILITY.pdf">Steps to Eligibility</a></li>
                  <li><a target="_" download="" href="/files/Somo_Entrepreneur_Map.pdf">Entrepreneur Map</a></li>
                  <li><a target="_" download="" href="/files/Somo_Code_of_Conduct.pdf">Code of Conduct</a></li>
                  <li><a target="_" download="" href="/files/Somo_Photography_Consent_Form.pdf">Photography Consent</a></li>
                  <li><a target="_" download="" href="/files/ACCELERATION_AGREEMENT_LETTER.pdf">Acceleration Agreement Letter</a></li>
                </ul>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() =>
                    history.push("/dashboard/onboarding/apply/start")
                  }
                >
                  Start Now <FaRocket />
                </Button>
              </div>
            </div>
          </Route>
          <Route path={`${path}/---future---`}>
            <div className={styles.creditTable}>
              {/* <Table striped hover className={styles.tableStyles}>
                <thead>
                  <tr>
                    <th>Loan ID</th>
                    <th>Monthly Repayment</th>
                    <th>Loan Fund</th>
                    <th>Status</th>
                    <th>Requested Amount</th>
                    <th>Balance</th>
                    <th>Approved Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((loanInstance, idx) => {
                    let loanBalance = loanInstance?.repayment
                      .filter((repaid) => {
                        return repaid.status === true;
                      })
                      .reduce((acc, curr) => {
                        return curr.scheduledAmount + acc;
                      }, 0);

                    loanBalance = loanInstance?.approvedAmount - loanBalance;

                    let overpayment = loanInstance?.repayment
                      .map((repayment) => repayment.penaltyFee)
                      .reduce((acc, curr) => {
                        return acc + curr;
                      }, 0);

                    return (
                      <tr key={idx}>
                        <td>{loanInstance._id.substring(0, 5)}</td>
                        <td>{`Ksh ${numberWithCommas(
                          (loanInstance.monthlyRepayment ? loanInstance.monthlyRepayment : '--')
                        )}`}</td>
                        <td>
                            {loanInstance.loanFundName}
                        </td>
                        <td>{_.startCase(loanInstance.status)}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.amount)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanBalance)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.approvedAmount)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {(!loans || loans.length === 0) && (
                <div className={styles.noLoanMessage}>
                  <p>Sorry you currently have no loan</p>
                  <img src={noLoan} alt="No loan history" height="250" />
                  <TiCancelOutline
                    className={styles.mobileNoLoan}
                    size="6em"
                    color="rgba(116, 23, 99, 0.6)"
                  />
                </div>
              )} */}
              
              <div className={styles.noLoanMessage}>
                <p>Sorry you currently have no loan</p>
                <img src={noLoan} alt="No loan history" height="250" />
                <TiCancelOutline
                  className={styles.mobileNoLoan}
                  size="6em"
                  color="rgba(116, 23, 99, 0.6)"
                />
              </div>
            </div>
          </Route>
          <Route path={`${path}/apply`}>
            <main className={styles.applyContainer}>
              <Row>
                <Col md={4}>
                  <ul className={styles.joinedBullets}>
                    {/* <li className={styles.active}>Proposal</li> */}
                    <li className={styles.active}><b>DigiKua & Portal Activation</b><b>1</b></li>
                    <li className={applyStageArray[location.pathname] > 0 && styles.active}><b>Passport</b><b>2</b></li>
                    <li className={applyStageArray[location.pathname] > 1 && styles.active}><b>ID Front</b><b>3</b></li>
                    <li className={applyStageArray[location.pathname] > 2 && styles.active}><b>ID Back</b><b>4</b></li>
                    <li className={applyStageArray[location.pathname] > 3 && styles.active}><b>KRA/TRA Pin</b><b>5</b></li>
                    <li className={applyStageArray[location.pathname] > 4 && styles.active}><b>Registration</b><b>6</b></li>
                    <li className={applyStageArray[location.pathname] > 5 && styles.active}><b>Business Bank</b><b>7</b></li>
                    <li className={applyStageArray[location.pathname] > 6 && styles.active}><b>Invest Overview</b><b>8</b></li>
                    <li className={applyStageArray[location.pathname] > 7 && styles.active}><b>Invest Video</b><b>9</b></li>
                    <li className={applyStageArray[location.pathname] > 8 && styles.active}><b>Invest Image</b><b>10</b></li>
                    <li className={applyStageArray[location.pathname] > 9 && styles.active}><b>Invest Logo</b><b>11</b></li>
                    <li className={applyStageArray[location.pathname] > 10 && styles.active}><b>Entrepreneur Map</b><b>12</b></li>
                    <li className={applyStageArray[location.pathname] > 11 && styles.active}><b>Code of conduct</b><b>13</b></li>
                    <li className={applyStageArray[location.pathname] > 12 && styles.active}><b>Photography Consent</b><b>14</b></li>
                    <li className={applyStageArray[location.pathname] > 13 && styles.active}><b>Acceleration Agreement letter</b><b>15</b></li>
                    {/* <li className={applyStageArray[location.pathname] > 14 && styles.active}>Fund</li>
                    <li
                      className={
                        applyStageArray[location.pathname] > 1 && styles.active
                      }
                    >
                      Amount
                    </li>
                    <li
                      className={
                        applyStageArray[location.pathname] > 2 && styles.active
                      }
                    >
                      Complete
                    </li> */}
                  </ul>
                </Col>
                <Col md={8}>
                  <div className={styles.applyForm}>
                    <Switch>
                      <Route path={`${path}/apply/start`}>
                        <OnboardPassportForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/id_front`}>
                        <OnboardIdFrontForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/id_back`}>
                        <OnboardIdBackForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/kra_pin`}>
                        <OnboardKRApinForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/b_name`}>
                        <OnboardBNameForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/b_bank`}>
                        <OnboardBBankForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/invest_overview`}>
                        <OnboardInvestOverviewForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/invest_video`}>
                        <OnboardInvestVideoForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/invest_image`}>
                        <OnboardInvestImageForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/invest_logo`}>
                        <OnboardInvestLogoForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/e_map`}>
                        <OnboardEmapForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/conduct`}>
                        <OnboardConductForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/photography`}>
                        <OnboardPhotographyForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/final`}>
                        <OnboardAgreementForm delegateApply={calculateLoan} />
                      </Route>
                      {/* <Route path={`${path}/apply/calculator`}>
                        <LoanProposalForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/fund`}>
                        <LoanFundForm delegateApply={calculateLoan} />
                      </Route>
                      <Route path={`${path}/apply/amount`}>
                        <LoanAmountForm delegateApply={calculateLoan} />
                      </Route> */}
                      {/* <Route path={`${path}/apply/` + /[a-z0-9]+/}>
                        <h2>Invalid Application Stage</h2>
                        <Button
                          bgColor="#cc6328"
                          size="sm"
                          color="#fff"
                          className="mt-4"
                          clicked={() =>
                            history.push("/dashboard/onboarding/apply/start")
                          }
                        >
                          Start Here <FaRocket />
                        </Button>
                      </Route> */}
                      {/* <Route path={`${path}/apply/contact-info`}>
                        <LoanContactForm submitContact={updateAddress} />
                      </Route>
                      <Route path={`${path}/apply/employer-info`}>
                        <EmployerInfoForm
                          submitEmployerInfo={updateEmployerInfo}
                        />
                      </Route>
                      <Route path={`${path}/apply/bank-info`}>
                        <BankInfoForm submitBankInfo={updateBankInfo} />
                      </Route> */}
                    </Switch>
                  </div>
                </Col>
              </Row>
            </main>
          </Route>
          <Route path={`${path}/success`}>
            <div className={styles.applicationComplete}>
              <FaCheckCircle size="4em" color="#cc6328" />
              <h2>Onboarding Complete!</h2>
              <p>
                Your onboarding is complete.
              </p>
              <p><a href="/dashboard/overview" style={{padding:'7px', display:'inline-block', border:'2px solid #007bff', margin:'14px auto', borderRadius:'5px'}}>Go to my dashboard</a></p>
            </div>
          </Route>
          <Route path={`${path}/too_late`}>
            <div className={styles.applicationComplete}>
              <FaBomb size="4em" color="#cc6328" />
              <h2>Too Late!</h2>
              <p style={{textAlign:'left'}}>
                We're sorry to notify you that your onboarding application cannot proceed since you took too long to complete it.
              </p>
              <p style={{textAlign:'left'}}>For more information: </p>
              <ul style={{textAlign:'left'}}>
                <li>reach out to your business coach </li>
                <li>or send an email to <a href="mailto:acceleration@somoafrica.org">Acceleration@somoafrica.org</a> </li>
                <li>or visit your nearest <a href="https://www.somoafrica.org/contacts" target="_">Somo Hub.</a></li>
              </ul>
            </div>
          </Route>
          <Route path={`${path}/approvals`}>
            <div className={styles.applicationComplete}>
              <FaStopwatch size="4em" color="#cc6328" />
              <h2>Awaiting Approvals!</h2>
              <p style={{textAlign:'left'}}>
                Wait as we review your application. After approval you'll continue.
                You can reach out to your business coach for more information.
              </p>
              <p style={{textAlign:'left'}}>The following steps are awaiting approval: </p>
              <ol style={{textAlign:'left'}} id="stepsAwaitingApproval">
                {/* <li></li> */}
              </ol>
            </div>
          </Route>
        </Switch>
      </Dashboard>
      <Modal
        className={styles.continueModal}
        size="sm"
        show={incomplete}
        onHide={() => {
          clearCompleteState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Continue Application</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have an incomplete loan request, please continue where you left
            off
          </p>
          <Button
            clicked={resumeApplication}
            fullWidth
            className="mt-4"
            bgColor="#cc6328"
            size="sm"
            color="#EBEBEB"
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Onboarding;
