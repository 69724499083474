import React, { useContext, useEffect } from "react";
import styles from "./Applicant.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Table } from "react-bootstrap";
import noLoan from "../../assets/no-loan.png";
import welcomeToAcc from "../../assets/welcomeBuruka.png";
import somoLogo from "../../assets/logo12.png";
import somoLogo2 from "../../assets/logo.png";
import { Row, Col, Modal } from "react-bootstrap";
import { Fa500Px, FaAccusoft, FaAmilia, FaArrowRight, FaBatteryEmpty, FaBomb, FaCarBattery, FaCheckCircle, FaClock, FaRocket, FaStopwatch, FaWalking } from "react-icons/fa";
import { TiCancelOutline } from "react-icons/ti";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
// import Loader from "react-spinners/BounceLoader";
// import Loader1 from "react-spinners/ScaleLoader";
// import Loader2 from "react-spinners/ClimbingBoxLoader";
// import Loader3 from "react-spinners/ClipLoader";
import LoaderPre from "react-spinners/ClockLoader";
// import Loader5 from "react-spinners/RingLoader";
// import Loader6 from "react-spinners/PuffLoader";
// import Loader7 from "react-spinners/RotateLoader";
import LoaderIntroWait from "react-spinners/HashLoader";
import LoaderIntro from "react-spinners/SyncLoader";
// import Loader10 from "react-spinners/PacmanLoader";
// import Loader11 from "react-spinners/PropagateLoader";
// import Loader12 from "react-spinners/PuffLoader";
// import Loader13 from "react-spinners/PulseLoader";
import ApplicantUniUploadForm from "../../components/ApplicantUniUploadForm/ApplicantUniUploadForm"; 

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { applicRoutes } from "../../routes/sidebarRoutes";
import _ from "lodash";

const Applicant = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const applyStageArray = {
    "/dashboard/applicant/apply/activate-done-at-login":0,
    "/dashboard/applicant/apply/start":1,
    "/dashboard/applicant/apply/id_front":2,
    "/dashboard/applicant/apply/id_back":3,
    "/dashboard/applicant/apply/kra_pin":4,
    "/dashboard/applicant/apply/b_name":5,
    "/dashboard/applicant/apply/b_bank":6,
    "/dashboard/applicant/apply/invest_overview":7,
    "/dashboard/applicant/apply/invest_video":8,
    "/dashboard/applicant/apply/invest_image":9,
    "/dashboard/applicant/apply/invest_logo":10,
    "/dashboard/applicant/apply/e_map":11,
    "/dashboard/applicant/apply/conduct":12,
    "/dashboard/applicant/apply/photography":13,
    "/dashboard/applicant/apply/final":14,
    // "/dashboard/consumer-credit/apply/calculator": 0,
    // "/dashboard/consumer-credit/apply/fund": 1,
    // "/dashboard/consumer-credit/apply/amount": 2,
    // "/dashboard/consumer-credit/apply/bank-info": 3,
  };

  const {
    state: { loans, error, currentLoanId, incomplete, loanApplicationStage },
    retrieveClientLoans,
    clearError,
    loanApply,
    addAddressForLoan,
    addWorkInfoForLoan,
    addBankInfoForLoan,
    clearCompleteState,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(()=>{
    // console.log('psr>>', user);
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetOnboardingState();      
    // });
    getCurrentOnboardingState(user.user_id);  
    function getCurrentOnboardingState(usrId){
      var y = new XMLHttpRequest();
      y.onload = function(){
        try{
          var a = JSON.parse(y.responseText);
          if(a.error){
            console.log('load Error > :3', y.responseText);
            toast.error("Error Occured " + a.error);
            if(a.data.path){
              if(window.location.pathname != a.data.path && a.data.path == '/dashboard/applicant/too_late'){
                history.push(a.data.path);
                // window.location.href = a.data.path;
                try{document.getElementById('onboarding_box_holder').style.display = 'none';}catch(e){}
              } else if(window.location.pathname == '/dashboard/applicant/too_late'){
                try{document.getElementById('onboarding_box_holder').style.display = 'none';}catch(e){}
              }
            }
          }
          // console.log('usr --a--> ', a);
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Helllllllllllllo');
          if(a.data.firstAttempt){
            if(window.location.pathname != '/dashboard/applicant/welcome'){
              history.push('/dashboard/applicant/welcome');
              // window.location.href = '/dashboard/applicant/welcome';
              return 0;
            }
            document.getElementById('preSetsLoaderA').style.display = 'none';
            document.getElementById('preSetsForm').style.display = 'block';
          } else {
            if(window.location.pathname == '/dashboard/applicant/welcome'){
              try{ document.getElementById('preSetsParent').style.display = 'none'; } catch(e){}
              if(user.onboardingComplete == true && a.data.path=='/dashboard/applicant/success'){
                window.location.href = '/dashboard/applicant/success';
              }
            }
            // console.log('>1>>', a.data);
          }
          // console.log('>>checking>>1', a.data.timeLeft);
          function incrementAnimate(options){
            try{
              var elm = options.elm;
              var max = parseInt(options.max);
              var min = options.min ? parseInt(options.min) : (max-7);
              var sec = options.sec ? options.sec : 90;
              var interval = setInterval(function() {
                elm.innerText = min + '%';
                if (min >= max) clearInterval(interval);
                min++;
              }, sec);
            } catch(er){

            }
          }
          document.getElementById('onboarding_progress').scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
          document.getElementById('onboarding_progress').innerText = a.data.progress;
          document.getElementById('onboarding_progress_b').innerText = a.data.stepsDone + '/' + a.data.totalSteps;
          // document.getElementById('onboarding_steps_p').innerText = a.data.pendingApproval;
          document.getElementById('onboarding_days_l').innerText = a.data.daysLeft + ' days, ' + a.data.timeLeft.hour + ' hrs, ' + a.data.timeLeft.mins + ' mins ' /*+ a.data.timeLeft.secs + ' sec'*/;

          localStorage.setItem('ApplicantSelfOnboard', JSON.stringify(a.data));
          incrementAnimate({
            elm: document.getElementById('onboarding_progress'), 
            max: a.data.progress.replace('%', ''), 
            min: 0, 
            sec: 120
          });
          if(document.getElementById('stepsAwaitingApproval')){
            var tmp = '';
            var pt = {};
            for(pt of a.data.toApproveSteps){
              tmp += '<li>' + pt.name + '</li>';
            }
            document.getElementById('stepsAwaitingApproval').innerHTML = tmp;
          }
          var quizOvv, quizSteps, quizTtl, quizInfo, quizInput, quizPrv;
          quizTtl = document.getElementById('questionsTitle');
          quizInfo = document.getElementById('questionsInfoTtl');
          quizInput = document.getElementById('questionsInput');
          quizPrv = document.getElementById('previewImg');

          if(quizOvv = document.getElementById('questionsOverview')){
            var tmp = '';
            var pt = {};
            for(pt of a.data.outQuizes){
              tmp += '<li>' + pt.question + '</li>';
            }
            quizOvv.innerHTML = tmp;
          }
          
          if(quizSteps = document.getElementById('questionsListSteps')){
            var tmp = '';
            var pt = {};
            for(pt of a.data.outQuizes){
              tmp += `<li class=${pt.order<a.data.outQuiz.order?styles.active:''}><b>${pt.question.length>21 ? pt.question.substr(0, 21)+'...' : pt.question }</b><b>${pt.order}</b></li>`;
            }
            
            // for(var p=(a.data.outQuizes.length-1); p>=0; p--){
            //   tmp += `<li class=${a.data.outQuizes[p].order<a.data.outQuiz.order?styles.active:''}><b>${a.data.outQuizes[p].question.length>21 ? a.data.outQuizes[p].question.substr(0, 21)+'...' : a.data.outQuizes[p].question }</b><b>${pt.order}</b></li>`;
            // }
            quizSteps.innerHTML = tmp;
          }
          
          if(quizTtl && quizInfo && quizInput && quizPrv){
            var inputHTML = ``;
            if(a.data.outQuiz.type == 'file'){
              document.getElementById('questionsInput').innerHTML='';
              document.getElementById('proposalUploadHolder').style.display='block';
              try{
                var fileTypes = {
                  "*/*" : "All Files",
                  "*/*" : "Image",
                  ".pptx" : "Presentation / Powerpoint",
                  ".doc, .docx" : "Document / MS Word",
                  ".xlsx, .csv" : "Spreadsheet / MS Excell",
                  ".pdf" : "PDF",
                  "video/*" : "Video",
                  "audio/*" : "Audio"
                };
                document.getElementById('proposalUpload').setAttribute('accept', a.data.outQuiz.fileType);
              } catch(er){
                console.log('Failed to limit file types -->', er);
              }
            } else if(a.data.outQuiz.type == 'number'){
              inputHTML = `<input type="number" id="currentFormInput" class=${styles.formInput} "/>`;
            } else if(a.data.outQuiz.type == 'textarea'){
              inputHTML = `<textarea id="currentFormInput" class=${styles.formInput} " rows="9"></textarea>`;
            } else if(a.data.outQuiz.type == 'text'){
              inputHTML = `<input id="currentFormInput" class=${styles.formInput} "/>`;
            } else if(a.data.outQuiz.type == 'checkbox'){
              var tmp = `<select class=${styles.formInput} id="currentFormInput" multiple>`;
              for(pt of a.data.outQuiz.options){ tmp += `<option value="${pt}">${pt}</option>`; }
              tmp += `</select>`;
              inputHTML = tmp;
            } else if(a.data.outQuiz.type == 'select'){
              var tmp = `<select class=${styles.formInput} id="currentFormInput">`;
              for(pt of a.data.outQuiz.options){ tmp += `<option value="${pt}">${pt}</option>`; }
              tmp += `</select>`;
              inputHTML = tmp;
            }

            if(a.data.outQuiz.type !== 'file'){
              document.getElementById('proposalUploadHolder').innerHTML='';
              quizPrv.style.display = "none";
            }
            quizTtl.innerHTML = a.data.outQuiz.question;
            if(fileTypes){
              quizInfo.innerHTML = '*This field is required' + (fileTypes[a.data.outQuiz.fileType] ? ' -> <b>'+fileTypes[a.data.outQuiz.fileType]+' file</b>' : '');
            } else {
              quizInfo.innerHTML = a.data.outQuiz.required ? '*This field is required' : '';
            }
            console.log('------->>', a.data.outQuiz);
            quizInput.innerHTML = inputHTML + `<input type="hidden" id="_quizId" name="quizId" value="${a.data.outQuiz._id}"/>`;
          }
          // console.log('-ttl->', document.getElementById('questionsTitle'));
          // console.log('-info->', document.getElementById('questionsInfoTtl'));
          // console.log('-quiz->', document.getElementById('questionsInput'));
          // console.log('-prv->', document.getElementById('previewImg'));
          // <FileUploadButton
          //   label="Choose File"
          //   icon={<FaCloudUploadAlt className="ml-3" size="1.1em" />}
          //   id="proposalUpload"
          //   fileRef={passportFileRef}
          // />
          // <textarea id="currentFormInput" className={styles.formInput} placeholder="Enter your Somo invest overview" rows="9"></textarea>
          
          // console.log('>>checking>>2', a.data.timeLeft);
          if(a.data.rejectedSteps.length > 0){
            toast.error(a.msg);
          }
          if(a.data.path){
            if(a.data.path == '/dashboard/applicant/success'){
              var info = JSON.parse(localStorage.gypsy);
              info.user.onboardingComplete = true;
              localStorage.setItem('gypsy', JSON.stringify(info));
            }
            if(!user.useNewOnboarding){
              toast.info('Onboarding was done with the old manual system');
            } //else toast.info('Onboarding was done with the new automatic system');
            if(window.location.pathname != a.data.path && ((window.location.pathname == '/dashboard/applicant/welcome' && a.data.stepsDone>0 && a.data.path != '/dashboard/applicant/apply/start'))){
              toast.info("Continue with your onboarding application");
              // if(window.location.pathname != '/dashboard/applicant/welcome'){
                history.push(a.data.path);
              // }
              if([
                '/dashboard/applicant/approvals',
                '/dashboard/applicant/success',
                '/dashboard/applicant/too_late'
              ].includes(a.data.path)) window.location.href = a.data.path;
            }
          } else {
          }
        } catch(er){
          console.log('load Error > :2', y.responseText, er);
        }
      };
      y.onerror = function(){
        console.log('load Error > :1', y.responseText);
      };
      y.open('GET', globalVars.baseUrl + globalVars.getApplicantState.replace(':user_id', usrId) + '?start=1');
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.send();
    }
  });

  // useEffect(() => {
  //   retrieveClientLoans();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      clearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const placeholderFun = (data) => {
    // loanApply(data, user.user_id);
  };

  const calculateLoan = (data) => {
    loanApply(data, user.user_id);
  };

  const updateAddress = (data) => {
    addAddressForLoan(data, currentLoanId);
  };

  const updateEmployerInfo = (data) => {
    addWorkInfoForLoan(data, currentLoanId);
  };

  const updateBankInfo = (data) => {
    addBankInfoForLoan(data, currentLoanId);
  };

  const resumeApplication = () => {
    clearCompleteState();
    if (loanApplicationStage === "calculated") {
      history.push("/dashboard/consumer-credit/apply/contact-info");
    }
    if (loanApplicationStage === "address_added") {
      history.push("/dashboard/consumer-credit/apply/employer-info");
    }
    if (loanApplicationStage === "employer_added") {
      history.push("/dashboard/consumer-credit/apply/bank-info");
    }
  };

  function handlePreSetsForm(){
    var usrLoc = document.getElementById('locationValue').value;
    if(!usrLoc){
      document.getElementById('preSetsInfo').innerText = 'Select the location of the hub closest to you';
      toast.error('Select the location of the hub closest to you');
      return 0;
    }
    document.getElementById('preSetsLoader').style.display = 'inline';
    document.getElementById('preSetsLoaderWait').style.display = 'flex';
    document.getElementById('preSetsInfo').innerText = '';
    var tmt = setTimeout(function(){window.location.reload();}, 1000*60*3);
    var x = new XMLHttpRequest();
    x.onload = function(){
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
      try{ clearTimeout(tmt); } catch(er){ console.log(er); }
      try{
        var a = JSON.parse(x.responseText);
        if(!a.status){
          console.log('load Error > :3', x.responseText);
          toast.error("Error!! " + a.message);
          document.getElementById('preSetsInfo').innerText = (a.error ? a.error : "Error Occured");
        } else {
          document.getElementById('preSetsParent').style.display = 'none';
          window.location.reload();
        }
        console.log('res', a);
      } catch(er){
        console.log('load Error > :2', x.responseText, er);
        toast.error("Error!! " + er);
        document.getElementById('preSetsInfo').innerText = ("Error Occured" + er);
      }
    };
    x.onerror = function(){
      try{ clearTimeout(tmt); } catch(er){ console.log(er); }
      console.log('load Error > :1', x.responseText);
      toast.error('Error! Try again');
      document.getElementById('preSetsInfo').innerText = 'Error! Try again';
      document.getElementById('preSetsLoader').style.display = 'none';
      document.getElementById('preSetsLoaderWait').style.display = 'none';
    };
    x.open('GET', globalVars.baseUrl + globalVars.getApplicantState.replace(':user_id', user.user_id) + '?location=' + usrLoc);
    x.setRequestHeader("Authorization", `Bearer ` + resolveToken());
    x.send();
  }

  return (
    <>
      <Dashboard sidebarRoutes={applicRoutes} location={location}>
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <div id="onboarding_box_holder">
            <h2>Application <span id="onboarding_progress">1%</span></h2>
            <p style={{marginBottom:'0.1rem'}}>Your have completed  <b><span id="onboarding_progress_b">1/~</span></b> application steps<br/>
            {/* <b><span id="onboarding_steps_p">0</span></b> steps pending approval */}
            </p>
            <div style={{display:'flex', alignItems:'center', position:'relative', paddingLeft:'28px'}}>
              <span className={styles.timer}><LoaderPre/></span>
              <span id="onboarding_days_l" style={{display:'inline-flex', padding: '0 3px 0 9px'}}>~ days </span> to deadline
            </div>
          </div>
          {location.pathname === "/dashboard/applicant/welcome" && (
            <Button
              bgColor="#cc6328"
              size="sm"
              color="#fff"
              className="mt-4"
              clicked={() =>
                history.push("/dashboard/applicant/apply/start")
              }
            >
              Start Now <FaRocket />
            </Button>
          )}
        </div>
        <Switch>
          <Route path={`${path}/welcome`}>
            <div className={styles.preSets} id="preSetsParent">
              <div>
                <span id="preSetsLoaderA" style={{display:'flex', alignItems:'center', justifyContent:'center', height:'28px'}}>Loading...&nbsp;<LoaderPre/></span>
                <form id="preSetsForm" style={{display:'none'}} onSubmit={(e)=>{e.preventDefault(); handlePreSetsForm();}}>
                  <img src={somoLogo} alt="Somo" />
                  <span id="preSetsInfo"></span>
                  <p>Select your Hub location</p>
                  <label for="proposalUpload">
                    <select id="locationValue" name="location" onChange={(e)=>{e.preventDefault();}}>
                      <option value="" selected={true} disabled={true}>Select Location</option>
                      <option value="Nairobi">Nairobi</option>
                      <option value="Kisumu">Kisumu</option>
                      <option value="Mombasa">Mombasa</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Nakuru">Nakuru</option>
                    </select>
                  </label>
                  <button type="submit" onClick={(e)=>{e.preventDefault(); handlePreSetsForm();}}>
                    Save & Continue
                    <span id="preSetsLoader" style={{display:'none'}}><LoaderIntro/></span>
                  </button>
                  <div id="preSetsLoaderWait" className={styles.preSetsWait} style={{display:'nones'}}>
                    <LoaderIntroWait/>
                    <p>
                      <b>Setting Up Your Somo Space...</b><br/>
                      This may take a few minutes (2 to 5 mins)<br/>
                      Be patient and do not refresh the page.<br/>
                      Leave everything unto us.
                    </p>
                    <img src={somoLogo2} alt="Somo" />
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.creditTable}>
              <div className={styles.welcomeAcc}>
                <h2>Welcome to Somo Buruka Program</h2>
                <img src={welcomeToAcc} alt="Welcome to Somo Buruka Program" style={{maxHeight:"250px"}} />
                <hr/><p>Congratulations for making it this far. It's time to submit your application.<br/> 
                  Here's what you need to submit to complete your application process.
                </p>
                <ol className={styles.steps} id="questionsOverview">
                  <LoaderIntro/>
                  {/* <li>Question 1</li> */}
                </ol>
                <i>
                  *
                </i>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() =>
                    history.push("/dashboard/applicant/apply/start")
                  }
                >
                  Start Now <FaRocket />
                </Button>
              </div>
            </div>
          </Route>
          <Route path={`${path}/---future---`}>
            <div className={styles.creditTable}>
              {/* <Table striped hover className={styles.tableStyles}>
                <thead>
                  <tr>
                    <th>Loan ID</th>
                    <th>Monthly Repayment</th>
                    <th>Loan Fund</th>
                    <th>Status</th>
                    <th>Requested Amount</th>
                    <th>Balance</th>
                    <th>Approved Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((loanInstance, idx) => {
                    let loanBalance = loanInstance?.repayment
                      .filter((repaid) => {
                        return repaid.status === true;
                      })
                      .reduce((acc, curr) => {
                        return curr.scheduledAmount + acc;
                      }, 0);

                    loanBalance = loanInstance?.approvedAmount - loanBalance;

                    let overpayment = loanInstance?.repayment
                      .map((repayment) => repayment.penaltyFee)
                      .reduce((acc, curr) => {
                        return acc + curr;
                      }, 0);

                    return (
                      <tr key={idx}>
                        <td>{loanInstance._id.substring(0, 5)}</td>
                        <td>{`Ksh ${numberWithCommas(
                          (loanInstance.monthlyRepayment ? loanInstance.monthlyRepayment : '--')
                        )}`}</td>
                        <td>
                            {loanInstance.loanFundName}
                        </td>
                        <td>{_.startCase(loanInstance.status)}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.amount)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanBalance)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.approvedAmount)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {(!loans || loans.length === 0) && (
                <div className={styles.noLoanMessage}>
                  <p>Sorry you currently have no loan</p>
                  <img src={noLoan} alt="No loan history" height="250" />
                  <TiCancelOutline
                    className={styles.mobileNoLoan}
                    size="6em"
                    color="rgba(116, 23, 99, 0.6)"
                  />
                </div>
              )} */}
              
              <div className={styles.noLoanMessage}>
                <p>Sorry you currently have no loan</p>
                <img src={noLoan} alt="No loan history" height="250" />
                <TiCancelOutline
                  className={styles.mobileNoLoan}
                  size="6em"
                  color="rgba(116, 23, 99, 0.6)"
                />
              </div>
            </div>
          </Route>
          <Route path={`${path}/apply`}>
            <main className={styles.applyContainer}>
              <Row>
                <Col md={4}>
                  <ul className={styles.joinedBullets} id="questionsListSteps">
                    <LoaderIntro/>
                  </ul>
                </Col>
                <Col md={8}>
                  <div className={styles.applyForm}>
                    <Switch>
                      <Route path={`${path}/apply/:question`}>
                        <ApplicantUniUploadForm delegateApply={calculateLoan} />
                      </Route>
                    </Switch>
                  </div>
                </Col>
              </Row>
            </main>
          </Route>
          <Route path={`${path}/success`}>
            <div className={styles.applicationComplete}>
              <FaCheckCircle size="4em" color="#cc6328" />
              <h2>Onboarding Complete!</h2>
              <p>
                Your onboarding is complete.
              </p>
              <p><a href="/dashboard/overview" style={{padding:'7px', display:'inline-block', border:'2px solid #007bff', margin:'14px auto', borderRadius:'5px'}}>Go to my dashboard</a></p>
            </div>
          </Route>
          <Route path={`${path}/too_late`}>
            <div className={styles.applicationComplete}>
              <FaBomb size="4em" color="#cc6328" />
              <h2>Too Late!</h2>
              <p style={{textAlign:'left'}}>
                We're sorry to notify you that your Buruka Submission cannot proceed since the deadline has passed.
              </p>
              <p style={{textAlign:'left'}}>For more information: </p>
              <ul style={{textAlign:'left'}}>
                <li>reach out to your mentor or trainer </li>
                <li>or send an email to <a href="mailto:trainers@somoafrica.org">Trainers@somoafrica.org</a> </li>
                <li>or visit your nearest <a href="https://www.somoafrica.org/contacts" target="_">Somo Hub.</a></li>
              </ul>
            </div>
          </Route>
          <Route path={`${path}/approvals`}>
            <div className={styles.applicationComplete}>
              <div style={{position:"relative", width:"64px", margin:"auto"}}>
                <FaStopwatch size="4em" color="#cc6328" />
                <span style={{position:"absolute", left:"0", right:"0", top:0, bottom:"1px", transform: "scale(1.4)", display:"flex", justifyContent:"center", alignItems:"flex-end", color:"#000000", border:"2px dotted #cc6328", borderRadius:"50%"}}><LoaderPre color="#ffffff"/></span>
              </div>
              {/* <h2>Awaiting Judging!</h2> */}
              <h2>Submission Successful</h2>
              <p style={{textAlign:'left'}}>
                Your submission was successful and advanced to the judging round.<br/>
                For now, be calm and just wait.<br/>
              </p>
              <p style={{textAlign:'left'}}>Empowering you, to change the world </p>
              <ol style={{textAlign:'left'}} id="stepsAwaitingApproval">
                {/* <li></li> */}
              </ol>
            </div>
          </Route>
          <Route path={`${path}`}>
            <div className={styles.applicationComplete}>
              <FaRocket size="4em" color="#cc6328" />
              <FaWalking size="4em" color="#cc6328" />
              <h2>Something is not ok!</h2>
              <p style={{textAlign:'left'}}> Welcome. If you cannot start your application: </p>
              <ul style={{textAlign:'left'}} ids="stepsAwaitingApproval">
                <li>Click below to check your progress or</li>
                <li>If you have not accepted the invite, logout then accept the invite</li>
              </ul>
              <p style={{textAlign:'left'}}>Empowering you, to change the world </p>
              <iframe style={{width:"100%", minHeight:"240px"}} src="https://www.youtube.com/embed/Yi4Y4UdO5qg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <p><a href="/dashboard/applicant/welcome" style={{padding:'7px', display:'inline-block', border:'2px solid #007bff', margin:'14px auto', borderRadius:'5px'}}>Check my Progress</a></p>
            </div>
          </Route>
        </Switch>
      </Dashboard>
      <Modal
        className={styles.continueModal}
        size="sm"
        show={incomplete}
        onHide={() => {
          clearCompleteState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Continue Application</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have an incomplete loan request, please continue where you left
            off
          </p>
          <Button
            clicked={resumeApplication}
            fullWidth
            className="mt-4"
            bgColor="#cc6328"
            size="sm"
            color="#EBEBEB"
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Applicant;
