import React, { useRef, useEffect, useContext } from "react";
import styles from "./OnboardAgreementForm.module.scss";
import Button from "../Button/Button";
import FileUploadButton from "../FileUploadButton/FileUploadButton";
import { FaCloudUploadAlt } from "react-icons/fa";
import passportPlaceholder from "../../assets/Pdf.png";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
import { toast, ToastContainer } from 'react-toastify';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Context as AuthContext } from "../../context/AuthContext";

const OnboardAgreementForm = ({ submit }) => {
  const passportFileRef = useRef();
  var loading = false;
  const history = useHistory();
  const {
    state: { user },
  } = useContext(AuthContext);
  var pdfSignLink = globalVars.pdfSign;
  useEffect(()=>{
    if(document.getElementById('proposalUpload')){
      document.getElementById('proposalUpload').addEventListener('change', function(e){
        e.stopImmediatePropagation();
        console.log('chg>>', this, this.files, user);
        try{
          document.getElementById('previewImg').src = URL.createObjectURL(this.files[0]);
        } catch(er){
          console.log('img prevw>', er);
        }
      });
      document.getElementById('proposalUpload').setAttribute('accept', 'image/*, application/pdf');
    }
  });

  const handleSubmit = () => {
    const passportFile = passportFileRef.current.files[0];
    if(!passportFile){
      toast.error("Select your Agreement letter to continue");
      return 0;
    }
    var yyy = passportFile.name.split('.');
    try{ var onboardInfo = JSON.parse(localStorage.getItem('AccSelfOnboard')); } catch(er){ var onboardInfo = {}; }
    // console.log('>>file', passportFile, onboardInfo);
    var loadingOff = document.getElementById('loading-off');
    var loadingOn = document.getElementById('loading-on');
    var frm = new FormData(document.getElementById('proposalUploadForm'));
    frm.append('file', passportFile);
    frm.append('fileName', 'Agreement letter ' + user.firstName +' '+ user.lastName + '.' + yyy[yyy.length-1]);
    frm.append('folderId', onboardInfo.infoFolder);
    loadingOff.style.display = 'none';
    loadingOn.style.display = 'initial';

    
    uploadProposal(frm);
    function uploadProposal(formData){
      var z = new XMLHttpRequest();
      z.onload = function(){
        try{
          var a = JSON.parse(z.responseText);
          if(a.error){
            console.log('load Error > :3', z.responseText);
            handelFormResp(false, z.responseText);
          } else handelFormResp(true, a.data);
        } catch(er){
          console.log('load Error > :2', z.responseText, er);
          handelFormResp(false, z.responseText);
        }
      };
      z.onerror = function(){
        console.log('load Error > :1', z.responseText);
        handelFormResp(false, z.responseText);
      };
      z.open('POST', globalVars.uploadUrlOnboard);
      // z.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      z.send(formData);
    }
    function handelFormResp(status, data){
      if(!status){
        console.log('Error>>');
        var err = (typeof data == 'string' ? JSON.parse(data) : data);
        loadingOff.style.display = 'initial';
        loadingOn.style.display = 'none';
        toast.error("Upload Failed, Try again");
        return 0;
      } else if(data){
        console.log('proceed>', data);
        data = data.replace(/https:\/\/drive.google.com\/file\/d\//ig, '').replace(/\/view\?usp=sharing/ig, '');
        submitPassportLink(user.user_id);

        function submitPassportLink(usrId){
          var directionInfo = {here:'agreementLetter', next:"/dashboard/onboarding/approvals"};
          var y = new XMLHttpRequest();
          y.onload = function(){
            try{
              var a = JSON.parse(y.responseText);
              if(a.error){
                console.log('load Error > :3', y.responseText);
                handelPropResp(false, y.responseText);
              } else {
                handelPropResp(true, a.data);
              }
            } catch(er){
              console.log('load Error > :2', y.responseText, er);
              handelPropResp(false, y.responseText);
            }
          };
          y.onerror = function(){
            console.log('load Error > :1', y.responseText);
            handelPropResp(false, y.responseText);
          };
          y.open('POST', globalVars.baseUrl + globalVars.addOnboardUrl.replace(':user_id', usrId));
          y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
          y.setRequestHeader("Content-Type", "application/json");
          y.send(JSON.stringify({step:directionInfo.here, value:data}));

          function handelPropResp(status, resp){
            if(!status){
              var err = (typeof resp == 'string' ? JSON.parse(resp) : false);
              resp = err ? err : resp;
              console.log('Error>>');
              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';
              toast.error(resp.error ? resp.error : "Failed to save progress link, Try again");
              return 0;
            } else {
              console.log(resp);
              history.push(directionInfo.next);
            }
            
          }
        }
      } else {
        toast.error("Upload may have Failed, Unknown response");
      }      
    }
    // submit(idFile, passportFile, idType);
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.onboardPassportForm}>
      <div className={styles.passportVerify}>
        <h3>Acceleration Agreement Letter</h3>
        <p className={styles.info}>
          Finally, you're almost done. If you agree with the terms on the Agreement letter and all the onboarding documents you've submitted, sign the agreement letter and finish your onboarding.<br/>
          <a href={pdfSignLink+window.location.protocol+'//'+window.location.hostname+'/files/ACCELERATION_AGREEMENT_LETTER.pdf'} target="_" download="">Fill and Sign </a> or <a href={'/files/ACCELERATION_AGREEMENT_LETTER.pdf'} download="">Download </a> the Acceleration Agreement Letter<br/>
          Select your clearly visible document and upload.
        </p>
        <form id="proposalUploadForm">
          <label for="proposalUpload"><img src={passportPlaceholder} alt="Preview not available" id="previewImg" className={styles.passport} style={{maxHeight:"250px"}} /></label>
          <FileUploadButton
            label="Choose File"
            icon={<FaCloudUploadAlt className="ml-3" size="1.1em" />}
            id="proposalUpload"
            fileRef={passportFileRef}
          />
        </form>
      </div>
      <Button
        className="mt-4"
        fullWidth
        bgColor="#cc6328"
        size="lg"
        color="#EBEBEB"
        clicked={handleSubmit}
        disabled={loading}
      loading={loading}
      >
        <span id="loading-off">Save & Continue</span>
        <span id="loading-on" style={{display:"none"}}><Loader/></span>
      </Button>
      <i><hr/>*If your submission does not meet acceptabled standards, it will be rejected and you'll have to re-submit.</i>
    </div>
  );
};

export default OnboardAgreementForm;
