import React, { useRef, useEffect, useContext } from "react";
import styles from "./ApplicantUniUploadForm.module.scss";
import Button from "../Button/Button";
import FileUploadButton from "../FileUploadButton/FileUploadButton";
import { FaCloudUploadAlt } from "react-icons/fa";
import passportPlaceholder from "../../assets/user.png";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
import { toast, ToastContainer } from 'react-toastify';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Context as AuthContext } from "../../context/AuthContext";
import LoaderIntro from "react-spinners/SyncLoader";

const ApplicantUniUploadForm = ({ submit }) => {
  const passportFileRef = useRef();
  var loading = false;
  const history = useHistory();
  const {
    state: { user },
  } = useContext(AuthContext);
  useEffect(()=>{
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetOnboardingState();      
    // });
    // checkAndGetOnboardingState();

    if(document.getElementById('proposalUpload')){
      document.getElementById('proposalUpload').addEventListener('change', function(e){
        e.stopImmediatePropagation();
        console.log('chg>>', this, this.files, user);
        try{
          document.getElementById('previewImg').src = URL.createObjectURL(this.files[0]);
        } catch(er){
          console.log('img prevw>', er);
        }
      });
      document.getElementById('proposalUpload').setAttribute('accept', 'image/*');
    }

    function checkAndGetOnboardingState(){
      var y = new XMLHttpRequest();
      y.onload = function(){
        try{
          var a = JSON.parse(y.responseText);
          if(a.error){
            console.log('load Error > :3', y.responseText);
            toast.error(a.message ? a.message : "Error");
          }
          console.log('usr', a.user.user_id, a);
          if(a.user.user_id){
            getCurrentOnboardingState(a.user.user_id);
          } else {
            // toast.error(a.message ? a.message : "User read error");
          }
        } catch(er){
          console.log('load Error > :2', y.responseText, er);
        }
      };
      y.onerror = function(){
        console.log('load Error > :1', y.responseText);
      };
      y.open('GET', globalVars.baseUrl + globalVars.usrUnbox);
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.send();

      function getCurrentOnboardingState(usrId){
        var y = new XMLHttpRequest();
        y.onload = function(){
          try{
            var a = JSON.parse(y.responseText);
            if(a.error){
              console.log('load Error > :3', y.responseText);
            }
            console.log('usr --> ', a);
            if(a.data.path){
              if(window.location.pathname != a.data.path){
                toast.info("Continue with your onboarding application");
                // history.push(a.data.path);
                window.location.href = a.data.path;
              }
            } else {
            }
          } catch(er){
            console.log('load Error > :2', y.responseText, er);
          }
        };
        y.onerror = function(){
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getApplicantState.replace(':user_id', usrId) + '?start=1');
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    };
  });

  const handleSubmit = () => {
    // try{
      const passportFile = passportFileRef.current.files[0];
    // }catch(er){console.log(err);}
    if(!passportFile && document.getElementById('proposalUpload')){
      toast.error("Select your file to continue");
      return 0;
    }
    
    try{ var onboardInfo = JSON.parse(localStorage.getItem('ApplicantSelfOnboard')); } catch(er){ var onboardInfo = {}; }
    // console.log('>>file', passportFile, onboardInfo);
    var loadingOff = document.getElementById('loading-off');
    var loadingOn = document.getElementById('loading-on');
    var frm = new FormData(document.getElementById('proposalUploadForm'));

    if(passportFile && document.getElementById('proposalUpload')){
      var yyy = passportFile.name.split('.');
      frm.append('file', passportFile);
      frm.append('fileName', onboardInfo.outQuiz.question+' ' + user.firstName +' '+ user.lastName + '.' + yyy[yyy.length-1]);
      frm.append('folderId', onboardInfo.applicant.infoFolder);
    } else {
      var data = document.getElementById('currentFormInput').value;
    }
    var quizId = document.getElementById('_quizId').value;
    // console.log(quizId, onboardInfo.outQuiz._id);
    
    // // frm.append('folderId', '1PbSt9ZaxkHLbrk5C08mar-YnJaySv3VR');
    // frm.append('folderId', '1UYCiCgo38HakFIOswBb0FfTTTUluPTTo');
    loadingOff.style.display = 'none';
    loadingOn.style.display = 'initial';

    
    if(passportFile) uploadProposal(frm);
    else handelFormResp(true, data, false);
    function uploadProposal(formData){
      var z = new XMLHttpRequest();
      z.onload = function(){
        try{
          var a = JSON.parse(z.responseText);
          if(a.error){
            console.log('load Error > :3', z.responseText);
            handelFormResp(false, z.responseText);
          } else handelFormResp(true, a.data);
        } catch(er){
          console.log('load Error > :2', z.responseText, er);
          handelFormResp(false, z.responseText);
        }
      };
      z.onerror = function(){
        console.log('load Error > :1', z.responseText);
        handelFormResp(false, z.responseText);
      };
      z.open('POST', globalVars.uploadUrlOnboard);
      // z.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      z.send(formData);
    }

    function handelFormResp(status, data, viaUpload=true){
      if(!status){
        console.log('Error>>');
        var err = (typeof data == 'string' ? JSON.parse(data) : data);
        loadingOff.style.display = 'initial';
        loadingOn.style.display = 'none';
        toast.error("Upload Failed, Try again");
        return 0;
      } else if(data){
        console.log('proceed>', data);
        if(viaUpload) data = data.replace(/https:\/\/drive.google.com\/file\/d\//ig, '').replace(/\/view\?usp=sharing/ig, '');
        submitPassportLink(user.user_id);

        function submitPassportLink(usrId){
          var y = new XMLHttpRequest();
          y.onload = function(){
            try{
              var a = JSON.parse(y.responseText);
              if(a.error){
                console.log('load Error > :3', y.responseText);
                handelPropResp(false, y.responseText);
              } else {
                handelPropResp(true, a.data);
              }
            } catch(er){
              console.log('load Error > :2', y.responseText, er);
              handelPropResp(false, y.responseText);
            }
          };
          y.onerror = function(){
            console.log('load Error > :1', y.responseText);
            handelPropResp(false, y.responseText);
          };
          y.open('POST', globalVars.baseUrl + globalVars.addApplicantUrl.replace(':user_id', usrId));
          y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
          y.setRequestHeader("Content-Type", "application/json");
          y.send(JSON.stringify({step:quizId, value:data, cohort:onboardInfo.outQuiz.cohortId}));

          function handelPropResp(status, resp){
            if(!status){
              var err = (typeof resp == 'string' ? JSON.parse(resp) : false);
              resp = err ? err : resp;
              console.log('Error>>');
              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';
              toast.error(resp.error ? resp.error : "Failed to save progress, Try again");
              return 0;
            } else {
              console.log(resp);
              history.push("/dashboard/applicant/welcome");
              // history.push(resp.data.path);
            }
            
          }
        }
      } else {
        toast.error("Saving progress may have Failed, Unknown response or invalid data provided");
      }      
    }
    // submit(idFile, passportFile, idType);
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.onboardPassportForm}>
      <div className={styles.passportVerify}>
        <h3 id="questionsTitle"><LoaderIntro/></h3>
        <p className={styles.info} id="questionsInfoTtl"><LoaderIntro/></p>
        <form id="proposalUploadForm">
          <label for="proposalUpload"><img src={passportPlaceholder} alt="Preview not available" id="previewImg" className={styles.passport} style={{maxHeight:"250px"}} /></label>
          <div id="questionsInput"><LoaderIntro/></div>
          <div id="proposalUploadHolder" style={{display:"none!important"}}>
           <FileUploadButton
             label="Choose File"
             icon={<FaCloudUploadAlt className="ml-3" size="1.1em" />}
             id="proposalUpload"
             fileRef={passportFileRef}
           />
          </div>
        </form>
      </div>
      <Button
        className="mt-4"
        fullWidth
        bgColor="#cc6328"
        size="lg"
        color="#EBEBEB"
        clicked={handleSubmit}
        disabled={loading}
      loading={loading}
      >
        <span id="loading-off">Save & Continue</span>
        <span id="loading-on" style={{display:"none"}}><Loader/></span>
      </Button>
      <i><hr/>*</i>
    </div>
  );
};

export default ApplicantUniUploadForm;
