// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseUrl: (window.location.hostname=='d.somo.loan.pre' ? `http://d.somo.loan.api` : `https://api.loan.somoafrica.com`),
  // uploadUrl: (window.location.hostname=='d.somo.loan.pre' ? `http://d.somo.invest` : `https://invest.somoafrica.org`) + '/api/media/up/loan/?toGrive'
  uploadUrl: 'https://invest.somoafrica.org/api/media/up/loan/?toGrive',
  pmtCalcUrl: (window.location.hostname=='d.somo.loan.pre' ? `http://d.somo.invest` : `https://invest.somoafrica.org`) + '/api/data/loan/calculate/',
  usrUnbox: '/user/unbox',
  addProposalUrl: '/client/loan/proposal/:user_id',
  getLoanState: '/client/loan/get_state/:user_id',
  addLoanFundUrl: '/client/loan/fund/:user_id',
  addLoanAmount: '/client/loan/amount/:user_id',
  getOnboardingState: '/client/onboarding/get_state/:user_id',
  getApplicantState: '/client/applicant/get_state/:user_id',
  uploadUrlOnboard: 'https://invest.somoafrica.org/api/media/up/onboard/?toGrive',
  addOnboardUrl: '/client/onboarding/set_state/:user_id',
  addApplicantUrl: '/client/applicant/set_state/:user_id',
  pdfSign: (window.location.hostname=='d.somo.loan.pre' ? 'http://d.pdf.sign/?url=' : 'https://sign.somoafrica.com/?url=')
}