import React, { useState, useContext, useEffect } from "react";
import Logo from "../../assets/logo.png";
import styles from "./Invited.module.scss";
import NavBar from "../../components/NavBar/NavBar";
import { Row, Col } from "react-bootstrap";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import pageUrl from "../../routes/pageUrl";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Context as AuthContext } from "../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { validateInput } from "../../utils/validateInput";
import useNavigateAfterAuth from "../../hooks/useNavigateAfterAuth";
import makeReq from "../../api/gypsy-web";

const Invited = () => {
  const history = useHistory();

  const {
    state: { loading, error, redirectInactiveUser },
    loginUser,
    clearErrors,
    resendOtp,
  } = useContext(AuthContext);

  useEffect(() => {
    if (error) {
      toast.error(error);
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [signinValues, setSigninValues] = useState({
    email: "",
    // password: "",
  });

  const [invitedValues, setInvitedValues] = useState({
    email: "",
    name: "",
    gender: "",
    country: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
  });

  const [signinErrors, setSigninErrors] = useState({
    email: null,
    // password: null,
  });

  const [invitedErrors, setInvitedErrors] = useState({
    email: null,
    name: null,
    gender: null,
    country: null,
    phoneNo: null,
    password: null,
    confirmPassword: null,
  });

  const [signinAndNavigate] = useNavigateAfterAuth(
    signinValues.email,
    // signinValues.password
  );

  const handleSubmit = async() => {
    console.log('-->', signinValues);
    console.log('-->', invitedValues);
    const validated = validateInput(signinValues, setSigninErrors);
    const validated2 = validateInput(invitedValues, setInvitedErrors);
    console.log('v-->', validated);
    console.log('v2-->', validated2);
    console.log('-invites-->', document.getElementById('invitedPartA'), document.getElementById('invitedPartB'));
    var invitedPartA = document.getElementById('invitedPartA');
    var invitedPartB = document.getElementById('invitedPartB');

    try{
      // var resp = await makeReq.post("/client/invite", { emails:"", pwd:"" });
      if(invitedPartA.attributes._act.value=="1"){
        if(!validated){
          toast.warning('Fix all errors before proceeding');
          return 0;
        }
        var resp = await makeReq.post("/client/invite", signinValues);
        var res = resp.data;
        // console.log(resp);
        console.log(resp.data);
        if(res.status){
          toast.success(res.message);
          document.getElementById('invitedPartA').style.display = 'none';
          document.getElementById('invitedPartB').style.display = 'block';
          invitedPartA.setAttribute("_act", "0");
          invitedPartB.setAttribute("_act", "1");
          console.log(res);
          if(res.path){
            window.location.pathname = res.path;
          }
        } else {
          toast.error(res.message);
        }
      } else {
        if(!validated2){
          toast.warning('Fix all errors before proceeding');
          return 0;
        }
        if(invitedValues.password != invitedValues.confirmPassword){
          toast.warning('Your two passwords do not match');
          return 0;
        }
        var resp = await makeReq.post("/client/invite_onboard", invitedValues);
        var res = resp.data;
        console.log(resp);
        console.log(resp.data);
        if(res.status){
          toast.success(res.message);
          if(res.path){
            setTimeout(()=>{
              window.location.pathname = res.path;
              console.log('--->> 1', location.pathname);
            }, 3000);
          } else {
            setTimeout(()=>{
              window.location.pathname = '/signin';
              console.log('--->> 2', location.pathname);
            }, 3000);
          }
        } else {
          toast.error(res.message);
        }
      }
    } catch(er){
      console.log(`Error ---> ${er}`);
      console.log(er.message);
      toast.error(er.message);
    }
    // toast.error('Error');
    // toast.warning('Warning');
    // toast.success('Success');
    // if (validated) {
    //   signinAndNavigate(loginUser);
    // }
  };

  const handleSubmitWithKeyPress = (e) => {
    if (e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const location = useLocation();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (redirectInactiveUser) {
      (async () => {
        await resendOtp(signinValues.email);
        history.push(pageUrl.VERIFY_OTP_PAGE, {
          userEmail: signinValues.email,
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectInactiveUser]);

  return (
    <div className={styles.container}>
      <NavBar location={url} history={history} />
      <ToastContainer position="top-center" />
      <Link to={pageUrl.HOMEPAGE} style={{display:"none"}}>
        <img src={Logo} alt="Somo Logo" />
      </Link>
      <h1>Invited to Somo Africa</h1>
      <div className={styles.loginBox} id="invitedPartA" _act="1">
        <Row>
          <Col>
            <InputField
              type="email"
              label="Email"
              nameAttr="email"
              value={signinValues.email}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setSigninErrors({ ...signinErrors, email: null });
                setSigninValues({ ...signinValues, email: val });
                setInvitedValues({ ...invitedValues, email: val });
              }}
              error={signinErrors.email && signinErrors.email}
            />
            <p className={[styles.authLink, "mt-3"].join(" ")}>
              Enter your email that received the invitation
            </p>
          </Col>
        </Row>
        {/* <Row className="mt-3" style={{display:"none"}}>
          <Col>
            <InputField
              type="password"
              label="Password"
              nameAttr="password"
              value={signinValues.password}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setSigninErrors({ ...signinErrors, password: null });
                setSigninValues({ ...signinValues, password: val });
              }}
              error={signinErrors.password && signinErrors.password}
            />
          </Col>
        </Row> */}
        <Button
          clicked={handleSubmit}
          fullWidth
          className="mt-4"
          bgColor="#cc6328"
          size="lg"
          color="#EBEBEB"
          disabled={loading}
          loading={loading}
        >
          Next
        </Button>
        {/* <p className={[styles.authLink, "mt-3"].join(" ")}>
          If you don't have your credentials yet, reach out to your business coach 
          <br/>or <a href="mailto:loans@somoafrica.org">loans@somoafrica.org</a>
        </p> */}
        <br/><hr/>
        <iframe style={{width:"100%", minHeight:"240px", borderRadius:'7px'}} src="https://www.youtube.com/embed/Yi4Y4UdO5qg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      
      <div className={styles.loginBox} id="invitedPartB" style={{display:"none"}} _act="0">
        <Row>
          <Col>
            <InputField
              type="email"
              label="Email"
              nameAttr="email"
              value={signinValues.email}
              disable={true}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setInvitedErrors({ ...invitedErrors, email: null });
                setInvitedValues({ ...invitedValues, email: signinValues.email });
              }}
              error={invitedErrors.email && invitedErrors.email}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="text"
              label="Name"
              nameAttr="name"
              value={invitedValues.name}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setInvitedErrors({ ...invitedErrors, name: null });
                setInvitedValues({ ...invitedValues, name: val });
              }}
              error={invitedErrors.name && invitedErrors.name}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="tel"
              label="Phone"
              nameAttr="phoneNo"
              placeholder="+254... or +255..."
              value={invitedValues.phoneNo}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setInvitedErrors({ ...invitedErrors, phoneNo: null });
                setInvitedValues({ ...invitedValues, phoneNo: val });
              }}
              error={invitedErrors.phoneNo && invitedErrors.phoneNo}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="select"
              label="Gender"
              nameAttr="gender"
              options={['', 'Male','Female']}
              value={invitedValues.gender}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setInvitedErrors({ ...invitedErrors, gender: null });
                setInvitedValues({ ...invitedValues, gender: val });
              }}
              error={invitedErrors.gender && invitedErrors.gender}
            />
          </Col>
          <Col>
            <InputField
              type="select"
              label="Country"
              nameAttr="country"
              options={['', '+254','+255']}
              value={invitedValues.country}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setInvitedErrors({ ...invitedErrors, country: null });
                setInvitedValues({ ...invitedValues, country: val });
              }}
              error={invitedErrors.country && invitedErrors.country}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="password"
              label="Password"
              nameAttr="password"
              value={invitedValues.password}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setInvitedErrors({ ...invitedErrors, password: null });
                setInvitedValues({ ...invitedValues, password: val });
              }}
              error={invitedErrors.password && invitedErrors.password}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputField
              type="password"
              label="Confirm Password"
              nameAttr="confirmPassword"
              value={invitedValues.confirmPassword}
              handleKeyPress={(e) => handleSubmitWithKeyPress(e)}
              changed={(val) => {
                setInvitedErrors({ ...invitedErrors, confirmPassword: null });
                setInvitedValues({ ...invitedValues, confirmPassword: val });
              }}
              error={invitedErrors.confirmPassword && invitedErrors.confirmPassword}
            />
          </Col>
        </Row>
        <Button
          clicked={handleSubmit}
          fullWidth
          className="mt-4"
          bgColor="#cc6328"
          size="lg"
          color="#EBEBEB"
          disabled={loading}
          loading={loading}
        >
          Submit
        </Button>
        {/* <p className={[styles.authLink, "mt-3"].join(" ")}>
          If you don't have your credentials yet, reach out to your business coach 
          <br/>or <a href="mailto:loans@somoafrica.org">loans@somoafrica.org</a>
        </p> */}
      </div>
      <p><a href="/signin">All setup? Sign In here.</a></p>
    </div>
  );
};

export default Invited;
